.react-calendar__navigation {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 24px;

  .react-calendar__navigation__label {
    flex-grow: 0 !important;
    background: none;
    border: none;

    .data-year {
      font-size: 18px;
    }

  }

  .react-calendar__navigation__arrow.react-calendar__navigation__next2-button,
  .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
    display: none;
  }

  .react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
  .react-calendar__navigation__arrow.react-calendar__navigation__next-button {
    background: none;
    border: none;
    outline: none;
    margin-top: 6px;
  }

  .react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
    margin-right: 12px;
  }

  .react-calendar__navigation__arrow.react-calendar__navigation__next-button {
    margin-left: 12px;
  }

}

.react-calendar__viewContainer {
  .react-calendar__tile.react-calendar__year-view__months__month,
  .react-calendar__tile.react-calendar__decade-view__years__year,
  .react-calendar__tile.react-calendar__century-view__decades__decade {
    background: none;
    border: none;
    text-transform: capitalize;
  }

  .react-calendar__month-view__weekdays {
    margin-bottom: 4px;
  }

  .react-calendar__month-view__weekdays__weekday {
    text-align: center;

    abbr {
      text-decoration: none;
      text-transform: capitalize;
    }
  }

  .react-calendar__month-view__days {
    .react-calendar__tile {
      background: none;
      outline: none;
      border: none;
      color: #5842BE;
    }

    .react-calendar__tile.react-calendar__month-view__days__day[disabled] {
      color: gray;
    }

    .react-calendar__tile--active {
      abbr {
        color: #ffffff !important;
        background: #5842BE;
        padding: 3px;
      }
    }

    .react-calendar__tile--now {
      abbr {
        color: #5842BE;
        padding: 3px;
        border: 1px solid #5842BE;
      }
    }
  }
}

.calendar-info {
  margin-left: 41px;
}


@media (max-width: 1199px) {
  .calendar-info {
    margin-left: 33px;
  }
}

@media (max-width: 992px) {
  .calendar-info {
    margin-left: 37px;
  }
}

@media (max-width: 768px) {
  .calendar-info {
    margin-left: 24px;
  }
}

@media (max-width: 576px) {
  .calendar-info {
    margin-left: 4%;
  }
}