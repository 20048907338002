.currentBtn {
  transition: 0.3s;
  &:hover {
    color: #5842BE;
    border-bottom: 2px solid #5842BE!important;
    button {
      color: #5842BE;
    }
  }
  button {
    border: none;
  }
}

.currentBtn.active {
  border-bottom: 2px solid #5842BE!important;
  button {
    color: #5842BE;
  }
}
.currentBtn.active.error {
  border-bottom: 2px solid #FF0000FF!important;
}

.currentBtn.error {
  &:hover {
    border-bottom: 2px solid #FF0000FF!important;
  }
}


.phone-select {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.user-phone-input {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  padding: 11px 10px;
}

.else-block {
  span {
    z-index: 1;
    &:before, &:after {
      content: "";
      position: absolute;
      width: 45%;
      height: 1px;
      background: #DEDEDE;
      top: 50%;
      transform: translateY(50%);
      z-index: 0;
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
  }
}
