.time-btn {
  border-radius: 4px;
  transition: .2s;

  &:hover {
    border: 1px solid #5842BE!important;
    color: #5842BE;
    background: #F9F8FD!important;
  }
}

.time-btn.active {
  border: 1px solid #5842BE !important;
  color: #5842BE;
  background: #F9F8FD!important;
}

.master-photo__wrapper {
  max-width: 50px;
  max-height: 50px;
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    width: 50px;
  }
}

.master-free-time {
  .accordion-button::after {
    margin-left: 8px;
  }
}