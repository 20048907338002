@import url('https://fonts.googleapis.com/css2?family=Cookie&display=swap');
$success: #1AB394;
$primary: #5842BE;
$info: #8B929A;
$secondary: #9D9D9D;
$danger: #F44336;
$dark: #1F3354;
$orange: #F8AC59;
$ticket: #16B054;
$qr: #9B27AF;


$theme-colors: (
        "success": $success,
        "primary": $primary,
        "info": $info,
        "secondary": $secondary,
        "danger": $danger,
        "dark": $dark,
        "orange": $orange,
        "ticket": $ticket,
        "qr": $qr
);
$btn-font-weight: 700;
$h1-font-size: 3rem;
$h2-font-size: 2.625rem;
$h3-font-size: 2.25rem;
$h4-font-size: 1.75rem;
$h5-font-size: 1.375rem;

$h6-font-size: 0.875rem;
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1140px
);
$font-family-sans-serif: 'Arial';
$headings-font-weight: 700;
* {
  box-sizing: border-box;
  margin: 0;
}
.text-gray{
  color:#9D9D9D;
}

a:hover {
  color: #5842BE;
}

.pos-color {
  color: #5842BE;
}

.main_bg {
  background: #382788;
}

.main_text_color {
  color: #1F3354;
}

.icon_to_block i::before {
  display: block;
}

.fs-7 {
  font-size: 14px;
}

.fs-8 {
  font-size: 12px;
}
.fs-18 {
  font-size: 18px;
  @media screen and (max-width: 500px) {
    font-size: 14px;

  }
}
.main{
  min-height: 100vh;
}

.w-max-50 {
  max-width: 50%;
}

.form-check-input:checked{
  background-color: #ffffff!important;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("/img/check-sm.svg")!important;
  background-position: 50%;

  background-size: 8px;

}
.comment{
  height: 100px!important;
}
  $btn-padding-y: 11px!important;
  $btn-font-size: 14px!important;
  $btn-padding-y-sm: 6px!important;

.sticky-btn {
  position: fixed;
  z-index: 10;
  top: calc(84% + 4px);
}
.accordion{
  --bs-accordion-active-bg: white!important;
  --bs-accordion-active-color: $dark!important;
  --bs-accordion-border-color: white!important;
  --bs-accordion-btn-focus-border-color: white!important;
  --bs-accordion-btn-focus-box-shadow: white!important;
}
.branch-name{
  font-size: 22px;
  @media screen and (max-width: 500px) {
    font-size: 16px;

  }
}
.ticket-ads{
  background-image: url('/img/ads/img-ticket.svg'), url('/img/ads/ornament-ticket.svg'), radial-gradient(228.77% 105.08% at 81.01% 33.08%, #FFF 3.81%, #E4EDFF 63.67%, #D7FDE6 100%), radial-gradient(391.49% 69.09% at 66.99% 42.27%, #FFF 3.81%, #F2FFE4 32.06%, #E8FFF0 100%);
  background-position: bottom center, left center;
  background-repeat: no-repeat, no-repeat;
  background-color: #FFF;
}
.qr-ads{
  background-image:  url('/img/ads/ornament-qr.svg'), linear-gradient(333deg, #FFF 3.81%, #FBE4FF 43.23%, #E0DDFF 100%);
  background-position: top center, left center;
  background-repeat: no-repeat, no-repeat;
  background-color: #D4D4D4;

}

.btn-secondary:disabled {
  background-color: #DEDEDE!important;
  border-color: #DEDEDE!important;
}

.hidden {
  display: none;
}

ol,ul {
  list-style: none;
  margin: 0;
}
.ads-logo{
  height: 35px;
  @media screen and (max-width: 500px) {
    height: 19px;
  }
}

@media (max-width: 768px) {
  /* CUSTOM WIDTHS */
  .w-md-100 { width: 100%!important; }
  .w-max-50 {
    max-width: 100%;
  }
}
@import "bootstrap";