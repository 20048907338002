.owl-theme {
  .owl-dots {
    position: absolute;
    bottom: 5px;
    right: 50%;
    transform: translateX(50%);
    width: 100%;
    margin-top: 0;
    .owl-dot {
      span {
        width: 30px;
        height: 3px;
        border-radius: 0;
      }
    }
  }
}

.logo_wrapper {
  width: 68px;
  height: 75px;
  img {
    width: 100%;
    object-fit: cover;
  }
}

.owl-carousel {
  .image_wrapper {
    max-height: 300px;
    object-fit: cover;
  }
}

.slider-wrapper {
  .nav-btn-prev, .nav-btn-next {
    position: absolute;
    font-size: 32px;
    color: #adb5bd;
    padding: 0;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    &:focus {
      border: none;
    }
  }
  .nav-btn-prev {
    left: 10px;
  }
  .nav-btn-next {
    right: 10px;
  }
}