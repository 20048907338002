.error-type {
  font-size: 200px;
  color: #EEECF9;
  line-height: 120%;
}

.wrapper-error-img {
  img {
    display: block;
    width: 100%;
    object-fit: contain;
    max-height: 535px;
  }
}

@media (max-width: 414px) {
    .error-type {
      font-size: 150px;
    }
}