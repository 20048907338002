.page-btn {
  background: #ffffff;
  transition: .2s;
  &:hover {
    background: #5842BE;
    color: #ffffff;
  }
}

.nav-icon {
  transition: 0.2s;
  &:hover {
    color: #5842BE;
  }
}