.advertising {
  height: 100px;
  z-index: 10;
}

#fallback_ad {
  display: none;
  height: 100px !important;
}

.ad-footer {
  max-height: 100px;
}

ins.adsbygoogle[data-ad-status="unfilled"] {
  & #fallback_ad {
    display: block;
  }

  & iframe {
    display: none !important;
  }

  & > div:not(#fallback_ad) {
    display: none !important;
  }
}


