.comment {
  min-height: 257px !important;
}

.full-stars {
  text-align: center;
}

.full-stars .rating-group {
  display: inline-flex;
  flex-direction: row-reverse;
  position: relative;
}

.full-stars input {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, 50%);
  z-index: -1;
  opacity: 0;
}

.full-stars label {
  margin: 0;
  cursor: pointer;
}

.full-stars label svg {
  margin: 4px;
  height: 58px;
  width: 62px;
  fill: #F5F5F5;
  transition: fill 0.3s;
}

.full-stars input:checked ~ label svg {
  fill: #F8AC59;
}

.full-stars .rating-group input:hover ~ label svg {
  fill: #ffd148;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}


.close {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

.rev-comment {
  max-width: 231px;
  margin: 0 auto;
}
