.confirmed-text-color {
  color: #B9E5DD;
}

.confirmed-success {
  background: #1AB394;
}

.image-wrapper-confirm {
  max-width: 143px;
  width: 100%;
  max-height: 130px;
  height: 100%;
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 768px) {
  .image-wrapper-confirm {
    max-width: 85px;

  }
  .confirmed-text-color {
    font-size: 14px;
  }
}