.rating-container {
  height: 18px;
  width: 82px;
}

.line-height-14 {
  line-height: 14px;
}

.rating-empty-ic {
  z-index: 3;
}

.rating-full-ic {
  z-index: 1;
}

.rating-overlay {
  height: 18px;
  width: 97px;
  z-index: 2;
}