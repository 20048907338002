.service-price {
  background-color: #E8F7F4;
}

input.custom-search-style[type="search"]::-webkit-search-cancel-button {
  margin-right: 25px;
}

.service_image {
  max-height: 315px;
  img {
    max-height: 315px;
    object-fit: cover;
  }
}